// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Teachers Education | Learning Wings Education System',
  nakedPageSlug: 'teachers-education',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Teachers Education">
        Teachers Education
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Learning Wings endorses and believes in giving wings to its teachers
          to soar high and makes sure that the wings are strengthened from time
          to time by providing them with professional development so that the
          Cambridge Team stands apart from rest of the schools in the region.
        </p>
        <p>
          Briefly, this journey is never ending and Learning Wings puts its
          earnest efforts to upgrade skills of its teachers. So far, Learning
          Wings has trained approximately 900 teachers across all Cambridge
          Schools. Most of the teachers are working with Learning Wings where
          they are given opportunities to grow professionally and pursue with
          their passion.
        </p>
        <p>
          The ones who had to leave the organization for any reason, are very
          well placed with other schools where they are acknowledged due to
          their experience and trainings they had attended with Cambridge. What
          makes this all worthwhile is when these teachers come back to join
          Cambridge as the warmth and ambience pull them back.
        </p>
        <ul className="mask-p" style={{ listStyle: 'disc', paddingLeft: 40 }}>
          <li>
            Annually, Learning Wings organizes 3 days’ workshops in Jalandhar
            Schools, for all the teachers across all Learning Wings Schools
            where top leaders and subject experts are invited to conduct
            workshops to develop and enhance various skills among teachers.
          </li>
          <li>
            Continuous professional development opportunities are available for
            faculty at Cambridge Schools as per the guidance and course material
            designed by international bodies like Cambridge University UK,
            Newman University, Wolverhampton University, Early childhood
            Association. The senior leaders from these institutions conduct
            training workshops for our teachers from time to time.
          </li>
          <li>
            In-house workshops by subject experts are also conducted on a
            regular basis.
          </li>
          <li>
            Cascading of learning is done by the coordinators, heads of
            department or teachers, whosoever attends any workshop, be it
            general or subject specific, for the rest of the staff to learn from
            their experiences.
          </li>
          <li>
            Regular identified trainings and workshops are conducted by the
            subject heads during Wednesday stay backs.
          </li>
          <li>
            Learning Wings team visits its schools in various cities to conduct
            workshops requested by individual schools in their identified areas.
          </li>
        </ul>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
